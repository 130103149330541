<!-- <div class="app-body">
    <main class="main d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-md-3 mx-auto">
              <div >
              <img src="assets/img/brand/logo-trans.png" alt="First slide" style="display: block; width: 100%; " />
              </div>

            </div>
        </div>
      </div>
    </main>
  </div> -->
  <p></p>
  <div class="app-body" dir="ltr">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="loginForm" (submit)="onSubmit()">
                  <h1>تسجيل الدخول</h1>
                  <p class="text-muted">سجل الدخول لحسابك</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-screen-smartphone"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="966555555555" autocomplete="mobile" formControlName="username" required>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Password" autocomplete="current-password" formControlName="password" required>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary px-4">دخول</button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="btn btn-link px-0">!نسيت كلمة السر</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div>
                  <h2> ادارة جيرة</h2>
                  <p>يتطلب فتح الحساب الموافقة من ادارة جيرة علما بانه غير متوفر حاليا</p>
                  <button type="button" class="btn btn-primary active mt-3">التسجيل الان</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
